import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="app-container">
      <h1>EFCRIN</h1>
      <p>Get ready for an amazing experience</p>
      <div className="loading">Loading content...</div>
    </div>
  );
};

export default App;
